import React from 'react';
import { Button } from '@mantine/core';

import { FONT_COLOR_DEFAULT } from '@/config/theme';

const CTAButton = ({ onClick = () => {}, label, mt = 0 }) => {
  return (
    <Button
      onClick={onClick}
      size='xl'
      variant='outline'
      radius='xl'
      mt={mt}
      sx={{
        width: 'inherit',
        color: '#fff',
        borderColor: '#fff',
        fontFamily: 'Bagnard',
        fontWeight: 400,
        lineHeight: 1,
        transition: '.2s',

        '&:hover': {
          background: '#fff',
          color: FONT_COLOR_DEFAULT,
        },
      }}
    >
      {label?.toUpperCase()}
    </Button>
  );
};

export default CTAButton;
