import React, { useEffect, useState } from 'react';
import { Box, Button, Group, SimpleGrid, Stack, Title } from '@mantine/core';

import {
  FONT_COLOR_DEFAULT,
  FONT_FAMILY_DEFAULT,
  FONT_FAMILY_PRIMARY,
  SPACING_LG,
  SPACING_MD,
  SPACING_SM,
  SPACING_XS,
} from '../../config/theme';
import axios from 'axios';
import { useRouter } from 'next/router';
import RichText from '../_generic/RichText';
import { PAYLOAD_URL } from '@/config/env';
import { displayTextDate } from '@/helpers/date';
import Image from 'next/image';
import getCdnUrl from '@/utility/getCdnUrl';
import CTAButton from '../_generic/CTAButton';

const PUBLICATION_FONT_SECONDARY_OPACITY = 0.5;

const PublicationModalContent = ({ data, isMobile }) => {
  const [publication, setPublication] = useState(null);

  const cover_picture = publication?.cover_picture?.sizes?.normal
    ? publication.cover_picture.sizes.normal
    : publication?.cover_picture;

  const router = useRouter();

  useEffect(() => {
    const publication_id = router.query.id;
    if (publication_id) {
      const fetchPublication = async () => {
        const res = await axios.get(
          `${PAYLOAD_URL}/api/publications/${publication_id}?depth=2`
        );

        setPublication(res.data);
      };

      fetchPublication();
    }
  }, [router.query.id]);

  return (
    <>
      {publication && (
        <Box sx={{ color: '#fff', margin: SPACING_SM }}>
          <Title
            order={1}
            mt={SPACING_LG}
            mb={SPACING_MD}
            sx={{
              color: '#fff',
            }}
          >
            {publication.title}
          </Title>

          <SimpleGrid
            cols={isMobile ? 1 : 2}
            mt={SPACING_SM}
            mb={SPACING_MD}
            spacing={SPACING_MD}
            verticalSpacing={32}
          >
            <Stack spacing={SPACING_MD} sx={{ flex: 1 }}>
              {cover_picture && (
                <Image
                  src={getCdnUrl(cover_picture.url)}
                  height={cover_picture.height}
                  width={cover_picture.width}
                  loader={() => {
                    return getCdnUrl(cover_picture.url);
                  }}
                  alt={publication?.title ? publication.title : 'publication'}
                  style={{
                    maxWidth: '100%',
                    width: 'auto',
                    height: 'auto',
                  }}
                  unoptimized
                />
              )}

              <Stack
                spacing={SPACING_MD}
                sx={{ flexDirection: isMobile ? 'column-reverse' : 'column' }}
              >
                <span>
                  <b>Prix</b> : CHF 20.00
                </span>
                <Box sx={{ textAlign: isMobile ? 'center' : 'left' }}>
                  <CTAButton
                    label='Commander'
                    onClick={() => {
                      window.open(publication.order_link);
                    }}
                  />
                </Box>
              </Stack>
            </Stack>
            <div style={{ flex: 1 }}>
              <span style={{ marginBottom: SPACING_XS, fontWeight: 'bold' }}>
                Date de parution
              </span>
              <br />
              {displayTextDate(publication.publication_date, false)}
              <br />
              <br />
              <b>Textes</b>
              {publication.sequences.map((sequence) => {
                return (
                  <Group
                    key={sequence.title}
                    align={isMobile ? 'flex-start' : 'center'}
                    mb={isMobile ? SPACING_XS : 0}
                    spacing={isMobile ? 0 : 2 * SPACING_XS}
                    noWrap
                    sx={{
                      flexDirection: isMobile ? 'column-reverse' : 'row',
                    }}
                  >
                    <span style={{ fontStyle: 'italic', whiteSpace: 'nowrap' }}>
                      {sequence.title}
                    </span>
                    {sequence.author && (
                      <span
                        style={{
                          opacity: PUBLICATION_FONT_SECONDARY_OPACITY,
                          fontWeight: 'bold',
                          fontSize: 14,
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {sequence.author?.toUpperCase()}
                      </span>
                    )}
                  </Group>
                );
              })}
              {publication.coverdesigner && (
                <>
                  <br />
                  <b>Couverture</b>
                  <br />
                  {publication.coverdesigner}
                </>
              )}
            </div>
          </SimpleGrid>
          {publication.excerpts.map((excerpt) => (
            <Box
              key={excerpt.title}
              mt={SPACING_LG}
              sx={{
                fontFamily: FONT_FAMILY_PRIMARY,
                fontSize: 20,
              }}
            >
              <Group spacing={0} align='flex-start'>
                <Image
                  draggable={false}
                  style={{
                    marginTop: (SPACING_LG + SPACING_MD) / -3,
                    marginLeft: (SPACING_LG + SPACING_MD) / -8,
                    height: SPACING_LG + SPACING_MD,
                    width: SPACING_LG + SPACING_MD,
                    opacity: '25%',
                    userSelect: 'none',
                  }}
                  src={require('../../public/images/publications/double-quotes-left.svg')}
                  alt='quote left'
                  unoptimized
                />

                <Stack spacing={SPACING_MD} sx={{ maxWidth: '90%' }}>
                  {excerpt.title && (
                    <Title
                      order={2}
                      sx={{
                        display: 'inline',
                        color: '#fff',
                        fontSize: 28,
                      }}
                    >
                      {excerpt.title && excerpt.title?.toUpperCase()}
                    </Title>
                  )}

                  <Box
                    sx={{
                      display: 'inline-block',
                      width: 'inherit',
                      // lineBreak: 'strict',
                    }}
                  >
                    <RichText data={excerpt.content} paragraphSpacing={false} />
                  </Box>
                </Stack>
              </Group>
              <Stack align='flex-end'>
                <Box
                  sx={{
                    color: '#fff',
                    opacity: PUBLICATION_FONT_SECONDARY_OPACITY,
                    fontFamily: FONT_FAMILY_DEFAULT,
                    fontSize: 24,
                  }}
                >
                  {excerpt.author && excerpt.author}
                  {!isMobile && excerpt.sequence && (
                    <>
                      , <i>{excerpt.sequence}</i>
                    </>
                  )}
                  <Image
                    draggable={false}
                    style={{
                      marginRight: (SPACING_LG + SPACING_MD) / -8,
                      height: SPACING_LG + SPACING_MD,
                      width: SPACING_LG + SPACING_MD,
                      opacity: '50%',
                      userSelect: 'none',
                    }}
                    src={require('../../public/images/publications/double-quotes-right.svg')}
                    alt='quote right'
                    unoptimized
                  />
                </Box>
              </Stack>
            </Box>
          ))}
        </Box>
      )}
    </>
  );
};

export default PublicationModalContent;
