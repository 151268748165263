import React from 'react';
import { SimpleGrid } from '@mantine/core';
import { SPACING_MD } from '/config/theme';

const TextsContainer = ({ cols, isMobile, children }) => {
	return (
		<SimpleGrid
			spacing='md'
			mt={SPACING_MD}
			cols={isMobile ? 1 : cols ? cols : 2}
			sx={{ width: '100%' }}
		>
			{children}
		</SimpleGrid>
	);
};

export default TextsContainer;
