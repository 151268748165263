import { Box, Center, Group, Image, Stack, Text, Title } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { DateTime } from 'luxon';
import React from 'react';
import { COLOR_BLACK_SHINY } from '../../config/theme';
import Plume from '../_generic/Plume';
import { displayTextDate } from '@/helpers/date';

const WorkshopThumbnail = ({ data, onClick }) => {
	const { title, when_date, where } = data;

	const isMobile = useMediaQuery('(max-width: 900px)');

	const is_passed = when_date < new Date().toISOString().substring(0, 10);

	return (
		<Box
			sx={{
				background: 'rgba(239,235,239,0.9)',
				'&:hover': { background: '#EFEBE5' },
				transition: '.25s',
				cursor: 'pointer',
				userSelect: 'none',
			}}
			p={16}
			onClick={onClick}
		>
			<Title mb={16} order={3} sx={{ color: COLOR_BLACK_SHINY }}>
				{title}
			</Title>
			{!isMobile && (
				<Plume opacity={is_passed ? 0.15 : 1} isMobile={isMobile} />
			)}
			<Group sx={{ justifyContent: 'space-between' }}>
				<Text mt={16}>
					{displayTextDate(data.when_date, true)}
					<br />
					{where}
				</Text>
				{isMobile && (
					<Plume opacity={is_passed ? 0.15 : 1} isMobile={isMobile} />
				)}
			</Group>
		</Box>
	);
};

export default WorkshopThumbnail;
