import {
  COLOR_PRIMARY,
  FONT_COLOR_GREY,
  SPACING_LG,
  SPACING_MD,
  SPACING_SM,
  SPACING_XS,
} from '@/config/theme';
import useCaptcha from '@/hooks/useCaptcha';
import formErrorsHandler from '@/lib/formErrorsHandler';
import validate from '@/lib/validate';
import {
  Box,
  Button,
  Center,
  Checkbox,
  Group,
  Stack,
  Text,
  TextInput,
  Textarea,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import React from 'react';

const Memberships = ({ isMobile }) => {
  const form = useForm({
    initialValues: {
      email: '',
      fullname: '',
      address: '',
      postalcode: '',
      locality: '',
      newsletter: false,
      printed_publication: false,
      friend_member: false,
      hcaptcha: null,
    },
    validate: {
      email: validate.email,
      printed_publication: (value, values) =>
        value &&
        !(
          values.fullname &&
          values.address &&
          values.postalcode &&
          values.locality
        )
          ? "L'abonnement à la publication papier requiert une adresse complète."
          : null,
      friend_member: (value, values) =>
        value &&
        !(
          values.fullname &&
          values.address &&
          values.postalcode &&
          values.locality
        )
          ? 'Devenir membre requiert une adresse complète.'
          : null,
      hcaptcha: (value) =>
        value !== null ? null : 'Validation hCaptcha nécessaire',
    },
  });

  const [Captcha, captcha_ref] = useCaptcha();

  const submitHandler = async (formValues) => {
    const {
      email,
      fullname,
      address,
      postalcode,
      locality,
      message,
      newsletter,
      printed_publication,
      friend_member,
      hcaptcha,
    } = formValues;

    try {
      const res = await fetch('/api/memberships', {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          email,
          fullname,
          address,
          postalcode,
          locality,
          message,
          newsletter,
          printed_publication,
          friend_member,
          hcaptcha,
        }),
      });

      if (res?.status === 200) {
        showNotification({
          id: 'server-status',
          title: 'Succès',
          message:
            'Un email vous a été envoyé pour confirmer le statut de vos abonnements',
          color: 'lime',
          icon: <IconCheck />,
          autoClose: 4000,
        });

        form.reset();
      } else {
        showNotification({
          id: 'server-status',
          title: `Erreur ${res.status ? res.status : ''}`,
          message: `Une erreur est malheureusement survenue.`,
          color: 'red',
          icon: <IconX />,
          autoClose: 4000,
        });
      }
      captcha_ref.current.resetCaptcha();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Box mt={SPACING_MD} sx={{ position: 'relative' }}>
      <div
        id='abonnements'
        style={{ position: 'absolute', top: isMobile ? -450 : -150 }}
      />
      <form onSubmit={form.onSubmit(submitHandler, formErrorsHandler)}>
        <Stack
          sx={{
            // display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
          }}
          spacing={SPACING_LG}
        >
          <Stack justify='space-between' spacing={SPACING_MD} sx={{ flex: 1 }}>
            <Box>
              <Checkbox
                label="m'abonner à la newsletter (gratuit)"
                color='primary.6'
                size='md'
                {...form.getInputProps('newsletter', { type: 'checkbox' })}
              />
              <Text
                mt={SPACING_SM}
                ml={SPACING_MD}
                sx={{ fontStyle: 'italic', color: FONT_COLOR_GREY }}
              >
                Recevez chaque dimanche un e-mail qui présente brièvement les
                textes publiés sur le site ainsi que les informations
                importantes quant à l’actualité de la revue (ateliers, lectures,
                salons…).
              </Text>
            </Box>
            <Box>
              <Checkbox
                label='m’abonner à la publication papier (20.-/numéro)'
                color='primary.6'
                size='md'
                {...form.getInputProps('printed_publication', {
                  type: 'checkbox',
                })}
              />
              <Text
                mt={SPACING_SM}
                ml={SPACING_MD}
                sx={{ fontStyle: 'italic', color: FONT_COLOR_GREY }}
              >
                Vous recevez par la poste chaque numéro de la revue (textes
                inédits) dès sa sortie en librairie.
              </Text>
            </Box>
            <Box>
              <Checkbox
                label="Faire un don à L'Épître (prix libre)"
                color='primary.6'
                size='md'
                {...form.getInputProps('friend_member', { type: 'checkbox' })}
              />
              <Text
                mt={SPACING_SM}
                ml={SPACING_MD}
                sx={{ fontStyle: 'italic', color: FONT_COLOR_GREY }}
              >
                L’Épître a besoin de vous ! Remplissez le formulaire et nous
                prendrons contact avec vous rapidement ; ou effectuez un don
                directement à l'aide des coordonnées ci-dessous :
                {/* L'Épître a besoin de vous ! Soutenez la revue en faisant un don.
                Une facture avec le montant choisi vous sera envoyée. */}
              </Text>
            </Box>
            <Stack
              ml={32}
              // mt={SPACING_LG}
              //   mt={SPACING_SM}
              spacing={SPACING_LG}
              sx={{ flexDirection: 'row', justifyContent: 'flex-start' }}
            >
              <Box sx={{ fontWeight: 'bold', fontSize: 14, color: '#333' }}>
                Association L’Épître
                <br />
                CH06 0076 8300 1524 0810 1<br /> <br />
                Banque cantonale de Fribourg
                <br /> 1700 Fribourg
                <br />
                <br />
                L’Épître
                <br />
                Monséjour 7<br />
                1700 Fribourg
              </Box>
            </Stack>
          </Stack>
          <Stack spacing={SPACING_MD} sx={{ flex: 1 }}>
            <TextInput
              placeholder='Votre adresse email'
              variant='unstyled'
              {...form.getInputProps('email')}
              // disabled={
              // 	!(
              // 		form.getInputProps('newsletter').value ||
              // 		form.getInputProps('printed_publication').value ||
              // 		form.getInputProps('friend_member').value
              // 	)
              // }
            />
            {(!isMobile ||
              form.getInputProps('printed_publication').value ||
              form.getInputProps('friend_member').value) && (
              <>
                <TextInput
                  placeholder='Prénom et nom'
                  variant='unstyled'
                  {...form.getInputProps('fullname')}
                  disabled={
                    !(
                      form.getInputProps('printed_publication').value ||
                      form.getInputProps('friend_member').value
                    )
                  }
                />
                <TextInput
                  placeholder='Adresse'
                  variant='unstyled'
                  {...form.getInputProps('address')}
                  disabled={
                    !(
                      form.getInputProps('printed_publication').value ||
                      form.getInputProps('friend_member').value
                    )
                  }
                />
                <Group>
                  <TextInput
                    sx={{ width: 125 }}
                    placeholder='Code postal'
                    variant='unstyled'
                    {...form.getInputProps('postalcode')}
                    disabled={
                      !(
                        form.getInputProps('printed_publication').value ||
                        form.getInputProps('friend_member').value
                      )
                    }
                  />
                  <TextInput
                    placeholder='Localité'
                    variant='unstyled'
                    sx={{ flex: 1 }}
                    {...form.getInputProps('locality')}
                    disabled={
                      !(
                        form.getInputProps('printed_publication').value ||
                        form.getInputProps('friend_member').value
                      )
                    }
                  />
                </Group>
                <Textarea
                  variant='unstyled'
                  placeholder='Message (optionnel)'
                  sx={{ minHeight: 120 }}
                  {...form.getInputProps('message')}
                />
              </>
            )}
            <Captcha {...form.getInputProps('hcaptcha')} />
            <Button
              type='submit'
              variant='outline'
              radius='md'
              color='dark'
              sx={{
                '&:hover': { background: COLOR_PRIMARY },
                width: 150,
              }}
            >
              Soumettre
            </Button>
            <Box sx={{ marginTop: SPACING_SM, color: '#B85059' }}>
              Vous pouvez également soutenir la revue par le biais d'une
              contribution mensuelle sur{' '}
              <a
                style={{
                  color: '#B85059',
                  fontWeight: 'bold',
                  textDecoration: 'underline',
                }}
                href='https://www.kisskissbankbank.com/fr/projects/l-epitre-revue-de-releve-litteraire'
              >
                notre page de financement participatif KissKissBankBank
              </a>
              .
            </Box>
          </Stack>
        </Stack>
      </form>
    </Box>
  );
};

export default Memberships;
