import { showNotification } from '@mantine/notifications';
import { IconX } from '@tabler/icons-react';

const formErrorsHandler = (validationErrors) => {
	const notificationContent = (
		<ul style={{ paddingLeft: 16 }}>
			{Object.keys(validationErrors).map((key) => (
				<li key={key}>{validationErrors[key]}</li>
			))}
		</ul>
	);

	showNotification({
		id: 'form-errors',
		title: 'Le formulaire contient des erreurs',
		message: notificationContent,
		color: 'orange',
		icon: <IconX />,
		autoClose: 2000 + Object.keys(validationErrors).length * 1500,
	});
};

export default formErrorsHandler;
