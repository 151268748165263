import React from 'react';

import { Anchor, Box, Text, Title } from '@mantine/core';

import {
	COLOR_BLACK_SHINY,
	COLOR_GREY_DARK,
	COLOR_GREY_LIGHT,
	COLOR_PRIMARY,
	FONT_COLOR_DEFAULT,
	FONT_COLOR_GREY,
	FONT_FAMILY_PRIMARY,
} from '@/config/theme';

import { displayTextDate } from '@/helpers/date';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { displayTextTime } from '@/helpers/time';
import getCdnUrl from '@/utility/getCdnUrl';

const AgendaEventPreview = ({ data, isMobile, linkActive }) => {
	const image = isMobile
		? data?.image?.sizes?.normal
		: data.image?.sizes?.thumbnail;

	const router = useRouter();

	const clickHandler = () => {
		if (linkActive) {
			router.push(`?type=evenement&id=${data.id}`, undefined, {
				shallow: true,
			});
		}
	};

	return (
		<Box>
			<Text mb={16} sx={{ fontSize: 14, color: FONT_COLOR_GREY }}>
				{displayTextDate(data.when_date, true)}
				{data.time && ` à ${displayTextTime(data.time)}`}
				<br />
				{data.where?.toUpperCase()}
			</Text>

			<Box
				onClick={clickHandler}
				sx={{
					cursor: linkActive ? 'pointer' : 'default',

					'&:hover': {
						opacity: linkActive ? 0.8 : 1,
					},
				}}
			>
				{image && (
					<Image
						src={image.url}
						width={image.width}
						height={image.height}
						loader={() => getCdnUrl(image.url)}
						style={{
							width: '100%',
							height: 'auto',

							// '&:hover': {
							// 	opacity: 0.8,
							// },
						}}
						alt={data.title}
					/>
				)}
				<Title
					order={3}
					mt={16}
					mb={16}
					sx={(theme) => ({
						fontFamily: FONT_FAMILY_PRIMARY,
						fontWeight: 400,
						fontSize: 20,
						color: COLOR_BLACK_SHINY,
						cursor: linkActive ? 'pointer' : 'default',
						textDecoration: linkActive && isMobile ? 'underline' : 'none',
						// color: linkActive && isMobile ? COLOR_PRIMARY : FONT_COLOR_DEFAULT,
						color: COLOR_PRIMARY,

						// '&:hover': {
						// 	color: linkActive ? COLOR_PRIMARY : FONT_COLOR_DEFAULT,
						// 	textDecoration: linkActive ? 'underline' : 'none',
						// },
					})}
				>
					{data.title}
				</Title>
			</Box>
			<Text>{data.preview}</Text>
		</Box>
	);
};

export default AgendaEventPreview;
