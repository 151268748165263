const partners_data = [
  {
    url: 'https://www.theatreosses.ch/',
    image: require('../public/images/partners/2.png'),
  },
  {
    url: 'https://www.plf-editions.ch/',
    image: require('../public/images/partners/3.png'),
  },
  {
    url: 'https://www.maisonnoctua.ch/',
    image: require('../public/images/partners/4.png'),
  },
  {
    url: 'https://nouveaumonde.ch/fr',
    image: require('../public/images/partners/5.png'),
  },
  {
    url: 'https://m-r-l.ch/',
    image: require('../public/images/partners/6.jpeg'),
  },
  {
    url: 'https://www.equilibre-nuithonie.ch/fr',
    image: require('../public/images/partners/9.png'),
  },
  {
    url: 'https://www.collectif-abordage.ch/',
    image: require('../public/images/partners/8.png'),
  },
  {
    url: 'https://www.opera-lausanne.ch/',
    image: require('../public/images/partners/logo_opera_lausanne.jpeg'),
  },
  {
    url: 'https://looseantenna.fm/',
    image: require('../public/images/partners/10.png'),
  },
];

export default partners_data;
