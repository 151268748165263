import {
	FONT_COLOR_GREY,
	SPACING_LG,
	SPACING_MD,
	SPACING_SM,
	SPACING_XS,
} from '@/config/theme';
import { Box, Center, Container, Text, Title } from '@mantine/core';
import React, { useMemo } from 'react';
import RichText from '../_generic/RichText';
import Image from 'next/image';
import getCdnUrl from '@/utility/getCdnUrl';
import { displayTextDate } from '@/helpers/date';
import { displayTextTime } from '@/helpers/time';

const AgendaEventModalContent = ({ news, eventId, isMobile }) => {
	const data = useMemo(() => {
		return news.find((item) => item.id === eventId);
	}, [news, eventId]);

	const image = data?.image?.sizes?.normal;

	return (
		<>
			{data && (
				<>
					<Text
						mt={SPACING_LG}
						sx={{ fontSize: 20, color: FONT_COLOR_GREY, textAlign: 'center' }}
					>
						{displayTextDate(data.when_date, true)}
						{data.time && ` à ${displayTextTime(data.time)}`}
						<br />
						{data.where}
					</Text>
					<Title
						mt={SPACING_MD}
						mb={SPACING_MD}
						order={1}
						sx={{
							textAlign: 'center',
							fontSize: 40,
						}}
					>
						{data.title}
					</Title>
					<Container>
						<Box
							sx={{
								background: '#fff',
								padding: isMobile ? SPACING_SM : SPACING_LG,
							}}
						>
							<Box mb={SPACING_SM}>
								<RichText data={data.content} paragraphSpacing={true} />
							</Box>
							<Image
								src={image.url}
								height={image.height}
								width={image.width}
								style={{
									display: 'block',
									margin: 'auto',
									width: '100%',
									height: 'auto',
									objectFit: 'contain',
									// border: '1px solid #ddd',
									// boxShadow: `2px 2px 4px 2px #ddd`,
								}}
								loader={() => getCdnUrl(image.url)}
								alt={data.title}
							/>
						</Box>
					</Container>
				</>
			)}
		</>
	);
};

export default AgendaEventModalContent;
