import { Box, Center } from '@mantine/core';
import Image from 'next/image';
import React from 'react';

const ImageContainer = ({
  image,
  width,
  height,
  fit,
  position,
  sizes,
  imageMaxWidth,
}) => {
  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        // width: width ? width : '100%',
        ...(height ? { height } : {}),
        overflow: 'hidden',
      }}
    >
      <Image
        src={image}
        fill
        sizes={sizes ? sizes : '(max-width: 1000px) 75vw, 50vw'}
        style={{
          maxWidth: imageMaxWidth ? imageMaxWidth : '100%',
          objectFit: fit ? fit : 'contain',
          ...(position ? { objectPosition: position } : {}),
          display: 'block',
          margin: 'auto',
        }}
        alt='alt'
      />
    </div>
  );
};

export default ImageContainer;
