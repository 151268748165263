import { Box, Center, Flex, Grid, Group, SimpleGrid } from '@mantine/core';
import Image from 'next/image';
import React from 'react';
import { SPACING_LG, SPACING_MD } from '../config/theme';
import ImageContainer from './_generic/ImageContainer';
import Section from './_generic/Section';
import partners_data from '@/data/partners';

const partnerLogoHeight = 70;
const imageMaxWidth = '70%';

const Partners = ({ isMobile }) => {
  const span_size = isMobile ? 6 : 4;

  return (
    <Section color='white' title='Partenaires'>
      <Grid
        grow
        mt={SPACING_MD}
        mb={SPACING_MD}
        gutter={(isMobile ? 1 : 2) * SPACING_MD}
      >
        {partners_data.map((partner, i) => (
          <Grid.Col key={i} span={span_size}>
            <a target='_blank' href={partner.url}>
              <ImageContainer
                imageMaxWidth={imageMaxWidth}
                image={partner.image}
                height={partnerLogoHeight}
                sizes='(max-width: 500px) 25vw, 20vw'
              />
            </a>
          </Grid.Col>
        ))}
      </Grid>
    </Section>
  );
};

export default Partners;
