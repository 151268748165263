import React from 'react';
import { Box, Stack, Text, Title } from '@mantine/core';
import { DateTime } from 'luxon';
import {
	COLOR_BLACK_SHINY,
	COLOR_GREY_DARK,
	COLOR_SECONDARY,
	FONT_COLOR_DEFAULT,
	FONT_COLOR_GREY,
	SPACING_XS,
} from '../config/theme';
import publishedTextAuthors from '@/helpers/publishedTextAuthors';

const PublishedTextCard = ({ data, color, onClick, titleSize, height }) => {
	return (
		<Stack
			justify='space-between'
			sx={{
				minHeight: height ? height : 'inherit',
				padding: 16,
				background: 'white',
				transition: '.15s',
				cursor: 'pointer',

				'&:hover': {
					boxShadow: `2px 2px 5px 3px rgba(0,0,0,0.1)`,
					transform: 'scale(102%)',
				},
			}}
			onClick={() => {
				if (onClick) onClick(data);
			}}
		>
			<Title
				order={2}
				sx={{
					fontWeight: 400,
					fontSize: titleSize ? titleSize : 'inherit',
					color: color === 'primary' ? '#fff' : FONT_COLOR_DEFAULT,
					// fontSize: 20,
				}}
			>
				{data.title}
			</Title>
			<Stack mt={SPACING_XS}>
				<Text size={22}>{publishedTextAuthors(data.authors)}</Text>
				<Text color={FONT_COLOR_GREY}>
					{DateTime.fromISO(data.publication_date).toFormat('dd.MM.yyyy')}
					<br />
					Semaine n°{data.week}
				</Text>
			</Stack>
		</Stack>
	);
};

export default PublishedTextCard;
